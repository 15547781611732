import React, { FC, useContext } from "react"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import Logo from "src/images/structured-data-logo.png"
import Helmet from "react-helmet"
import { Props } from "./types"
import { LangContext } from "src/context/lang"
import { usePages } from "src/context/pages"

const SEO: FC<Props> = ({ hideDefaultJSONLD, noIndexPage, ...props }) => {
  const { lang, i18n } = useContext(LangContext)
  const { currentPathWithoutLang } = usePages()

  const canonicalURL = process.env.GATSBY_CANONICAL_URL
  const currentCanonicalURL = `${canonicalURL}${currentPathWithoutLang}`

  const languageAlternates = [
    ...i18n.locales
      .filter(locale => locale !== i18n.defaultLocale)
      .map(locale => ({
        hrefLang: locale,
        href: `${canonicalURL}/${locale}${currentPathWithoutLang}`,
      })),
    {
      hrefLang: "x-default",
      href: currentCanonicalURL,
    },
  ]

  return (
    <>
      <GatsbySeo
        canonical={currentCanonicalURL}
        languageAlternates={languageAlternates}
        noindex={process.env.GATSBY_REAL_PRODUCTION === "FALSE"}
        openGraph={{
          url: "https://www.getflow.com",
          title: "Flexible Project & Task Management Software for Teams - Flow",
          description:
            "Flow is an all-in-one tool that unleashes your team’s potential. It brings together your tasks, projects, timelines, files, and conversations, and integrates with your favorite tools, to help you achieve more together.",
          images: [
            {
              url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
              width: 800,
              height: 600,
              alt: "Flow logo",
            },
            {
              url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
              width: 900,
              height: 800,
              alt: "Flow logo",
            },
          ],
          site_name: "Flow",
        }}
        twitter={{
          handle: "@FlowApp",
          site: "@FlowApp",
          cardType: "summary_large_image",
        }}
        {...props}
      />
      {!hideDefaultJSONLD && (
        <JsonLd
          json={{
            "@context": "https://schema.org",
            "@id": "https://www.getflow.com/#organization",
            "@type": "Organization",
            name: "Flow",
            description:
              "Flow is an all-in-one tool that unleashes your team's potential. It brings together your tasks, projects, timelines, files, and conversations, and integrates with your favorite tools, to help you achieve more together.",
            legalName: "Flow Technologies Ltd",
            url: "https://www.getflow.com/",
            logo: `${Logo}`,
            foundingDate: "2010",
            subOrganization: {
              "@type": "Organization",
              name: "Flow Support",
              url: "https://www.flow.help",
              "@id": "https://www.flow.help/#organization",
            },
            address: {
              "@type": "PostalAddress",
              streetAddress: "101-524 Yates Street",
              addressLocality: "Victoria",
              addressRegion: "British Columbia",
              postalCode: "V8W 1K8",
              addressCountry: "Canada",
            },
            contactPoint: [
              {
                "@type": "ContactPoint",
                contactType: "sales",
                email: "sales@getflow.com",
                areaServed: "Worldwide",
              },
              {
                "@type": "ContactPoint",
                contactType: "customer support",
                email: "help@getflow.com",
                areaServed: "Worldwide",
                availableLanguage: ["English"],
              },
            ],
            sameAs: [
              "https://www.capterra.com/p/150388/Flow/",
              "https://www.getapp.com/collaboration-software/a/flow/",
              "https://www.g2.com/products/flow-flow/",
              "https://twitter.com/FlowApp",
              "https://twitter.com/GetFlowHelp",
              "https://www.facebook.com/flowapp/",
              "https://www.instagram.com/getflowapp/",
              "https://vimeo.com/GetFlow",
              "https://www.youtube.com/c/FlowApp",
              "https://www.linkedin.com/company/getflow/",
              "https://open.spotify.com/user/2w1cuk7ifxoog1cxloe6mrmjj?si=Lr0VNlE8Ti2NEhnqz-ZWjA",
              "https://medium.com/Flow",
            ],
          }}
        />
      )}
      <Helmet>
        <html lang={lang} />

        <link rel="mask-icon" href="/flow-icon.svg" color="#ffffff" />

        {noIndexPage ? <meta name="robots" content="noindex" /> : null}
      </Helmet>
    </>
  )
}

export default SEO
