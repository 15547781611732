import React, { FC, useContext } from "react"
import * as styles from "./styles.module.scss"
import { Link as GatsbyLink } from "gatsby"
import { Props } from "./types"
import isUrlInternal from "src/utils/isUrlInternal"
import { LangContext } from "src/context/lang"
import { usePages } from "src/context/pages"

const withSlash = (s = "", currentPath = "/") => {
  switch (s[0]) {
    case "/":
      return s
    case ".":
      return currentPath === "/" ? "" : currentPath
    default:
      return `/${s}`
  }
}

const Link: FC<Props> = ({ to, className, target, ...props }) => {
  const {
    lang: appLang,
    i18n: { defaultLocale },
  } = useContext(LangContext)
  const fullClass = `${styles.link}${className ? ` ${className}` : ""}`
  const { currentPathWithoutLang } = usePages()

  const lang = props.lang || appLang

  // Use Gatsby Link for internal links, and <a> for others
  // Use <a> if the link should be opened in a new window
  if (isUrlInternal(to) || to === ".") {
    let internalTo =
      lang === defaultLocale
        ? to
        : `/${lang}${withSlash(to, currentPathWithoutLang)}`

    if (to.startsWith("mailto:")) {
      return <a className={fullClass} href={to} target={target} {...props} />
    }

    if (internalTo === ".") {
      internalTo = currentPathWithoutLang
    }

    if (target === "_blank") {
      return (
        <a
          className={fullClass}
          href={internalTo}
          target={target}
          rel="noopener noreferrer"
          title="esblank"
          {...props}
        />
      )
    } else {
      return <GatsbyLink className={fullClass} to={internalTo} {...props} />
    }
  }
  return (
    <a
      className={fullClass}
      href={to}
      target={target}
      rel="noopener noreferrer"
      {...props}
    />
  )
}

export default Link
export { Props as LinkProps }
